import React from "react";
import { HeroWrapper, LandingBody, Video } from "./Components/Elements";

function App() {
  return (
    <HeroWrapper>
      <Video/>
      <LandingBody>INTRAK RIP</LandingBody>
    </HeroWrapper>
  );
}

export default App;
